.resident-overview-container {
    padding: 20px;
    text-align: center;
  }
  
  /* Step Navigation */
  .step-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 20px auto;
  }
  
  /* Progress Line BELOW Icons */
  .progress-line {
    position: absolute;
    bottom: -10px; /* Adjusted to ensure spacing below icons */
    left: 0; /* Ensures full width */
    width: 100%; /* Stretches across the container */
    height: 4px;
    background: #ddd;
    z-index: -1; /* Ensures it stays behind the icons */
  }
  
  /* Progress Fill */
  .progress-fill {
    height: 100%;
    background: #3498db;
    transition: width 0.3s ease-in-out;
  }
  
  .step-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    text-decoration: none;
    color: #333;
    padding: 10px;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .step-circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ccc;
    color: white;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 6px;
  }
  
  .step-icon {
    margin-right: 6px;
  }
  
  .step-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .step.active .step-circle {
    background-color: #1976d2;
  }
  
  .step.completed .step-circle {
    background-color: #4caf50;
  }
  
  .step:hover {
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
  /* Progress bar (same as before) */
  .progress-line {
    height: 4px;
    background-color: #eee;
    position: relative;
    margin-bottom: 10px;
  }
  
  .progress-fill {
    height: 4px;
    background-color: #1976d2;
    transition: width 0.3s ease-in-out;
  }
  
  .step-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }
  
  .step-buttons button {
    flex: 1 1 150px;
    padding: 10px;
    font-size: 16px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .step-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Responsive tweaks for small screens */
  @media (max-width: 768px) {
    .step {
      flex: 1 1 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
    }
  
    .step-info {
      align-items: flex-start;
      margin-left: 10px;
    }
  
    .step-icon {
      display: none;
    }
  }