.customer-dashboard {
  padding: 20px;
  margin-left: 250px;
  font-family: "Segoe UI", sans-serif;
  background-color: #f4f6f8;
}

.summary-cards {
  display: flex;
  gap: 16px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.card {
  background-color: #ffffff;
  padding: 20px;
  flex: 1 1 30%;
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  min-width: 200px;
}

.accordion-section {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
  padding: 12px 16px;
}

.accordion-header {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
}

.accordion-header:hover {
  background-color: #f0f2f5;
}

.community-stats {
  display: flex;
  gap: 16px;
  margin: 12px 0;
  font-size: 15px;
  font-weight: 500;
  color: #333;
  flex-wrap: wrap;
}

.stat-box {
  background: #f9f9f9;
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.trend-icon {
  font-weight: bold;
  font-size: 16px;
}
.trend-icon.up {
  color: green;
}
.trend-icon.down {
  color: red;
}

.accordion-content {
  padding: 8px 0;
}

.charts {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.chart-card {
  background-color: #fafafa;
  padding: 10px;
  border-radius: 8px;
  flex: 1 1 300px;
  max-width: 400px;
}

/* -------------------------- */
/* ✅ RESPONSIVE STYLES BELOW */
/* -------------------------- */

@media (max-width: 1024px) {
  .customer-dashboard {
    margin-left: 0; /* Remove sidebar offset on tablet/mobile */
    padding: 16px;
  }

  .summary-cards {
    flex-direction: column;
  }

  .card {
    flex: 1 1 100%;
    min-width: unset;
    font-size: 15px;
  }

  .accordion-header {
    font-size: 16px;
  }

  .community-stats {
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
  }

  .charts {
    flex-direction: column;
  }

  .chart-card {
    width: 100%;
    max-width: 100%;
    flex: 1 1 auto;
  }
}

@media (max-width: 600px) {
  .card {
    font-size: 14px;
    padding: 14px;
  }

  .accordion-section {
    padding: 10px;
  }

  .stat-box {
    font-size: 14px;
  }

  .accordion-header h2 {
    font-size: 15px;
  }
}